<template>
  <div>
    <el-form :inline="true" :model="queryForm" size="small">
      <el-form-item>
        <el-button @click="getParkData" size="small">刷新</el-button>
        <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="tableLoading" :data="tableData" :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
      border stripe size="small" style="width: 100%;" max-height="500px">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column prop="name" label="园区名称"></el-table-column>
      <el-table-column prop="province_info.name" label="省"></el-table-column>
      <el-table-column prop="city_info.name" label="市"></el-table-column>
      <el-table-column prop="district_info.name" label="区/县"></el-table-column>
      <el-table-column prop="address" label="地址"></el-table-column>
      <el-table-column label="是否启用" v-slot="{ row }">
        <el-tag v-if="row.status === 1" type="success" size="small">启用</el-tag>
        <el-tag v-else type="info" size="small">禁用</el-tag>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm @confirm="handleDel(row.id)" title="确定删除吗？">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, jumper" :total="total" :current-page.sync="queryForm.page"
        :page-size="pageSize" @current-change="getParkData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="formDialog" :close-on-click-modal="false" @close="closeFormDialog"
      width="700px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="园区名" prop="name">
              <el-input v-model="form.name" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="省/市/区" prop="areaValue">
              <el-cascader v-model="form.areaValue" :options="areaData" class="el-input_inner--rewrite">
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="详细地址" prop="address">
              <el-input v-model="form.address" type="textarea" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="是否启用">
          <el-switch v-model="form.status" :active-value="1" :inactive-value="0" active-color="#13ce66"
            inactive-color="#ff4949" active-text="启用" inactive-text="禁用">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeFormDialog">取消</el-button>
        <el-button :loading='formSubmitting' @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getAreaDataAPI
  } from '@/api/common.js'
  import {
    saveAreaDataAPI,
    getParkDataAPI,
    delParkAPI,
  } from '@/api/base-data.js'

  export default {
    name: 'Area',
    data() {
      return {
        // 表格相关
        queryForm: {
          page: 1,
        },
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,
        curRowData: null,

        // 省市区相关
        areaData: [],

        // 表单相关
        dialogTitle: '',
        formDialog: false,
        formSubmitting: false,
        form: {
          name: '',
          areaValue: [],
          address: '',
          status: 1,
        },
        rules: {
          name: [{
            required: true,
            message: '园区名不能为空',
            trigger: 'blur'
          }],
          areaValue: [{
            required: true,
            message: '省市区不能为空',
            trigger: 'change'
          }],
          address: [{
            required: true,
            message: '详细地址不能为空',
            trigger: 'blur'
          }]
        }
      }
    },
    created() {
      this.getAreaData()
      this.getParkData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.formDialog = true
        this.dialogTitle = '新增'
      },
      // 关闭表单的弹窗
      closeFormDialog() {
        this.$refs.form.resetFields()
        this.formDialog = false
      },
      // 编辑按钮
      handleEdit(row) {
        this.formDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))

        // 设置form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
        // 设置form.areaValue
        this.form.areaValue = [this.curRowData.province, this.curRowData.city, this.curRowData
          .district
        ]
      },
      // 删除按钮
      handleDel(id) {
        delParkAPI({
          id,
        }).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getParkData()
          }
        })
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return this.$message.warning('有必填项未填写')

          this.formSubmitting = true
          // 将form.areaValue的值分给province、city、district
          const copyForm = JSON.parse(JSON.stringify(this.form))
          copyForm.province = copyForm.areaValue[0]
          copyForm.city = copyForm.areaValue[1]
          copyForm.district = copyForm.areaValue[2]
          delete copyForm.areaValue
          // 如果是编辑，加上id
          if (this.dialogTitle === '编辑') {
            copyForm.id = this.curRowData.id
          }
          // 提交
          saveAreaDataAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeFormDialog()
            this.getParkData()
          }).finally(() => this.formSubmitting = false)
        })
      },
      // 获取省市区数据
      getAreaData() {
        getAreaDataAPI({
          model: 'Areas'
        }).then(res => this.areaData = res)
      },
      // 获取园区数据
      getParkData() {
        this.tableLoading = true

        getParkDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      }
    }
  }
</script>

<style scoped>
</style>